import React, { Component } from 'react';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import PageHeading from '../../components/PageHeading/PageHeading';
import ResourceTable from '../../components/ResourceTable/ResourceTable';

class TrainingRegulations extends Component {
  render() {
    const { data } = this.props;
    const { edges: resources } = data.allTrainingRegulationsListJson;
    const mappedResources = resources.map(resource => resource.node);

    return (
      <Layout>
        <Helmet
          title="VEYM | Training Regulations"
          meta={[
            {
              name: 'description',
              content:
                'Quy Chế Huấn Luyện - VEYM Official Training Regulations',
            },
          ]}
        />
        <PageHeading>Training Regulations</PageHeading>
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/resources">Resources</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Training Regulations</BreadcrumbItem>
          </Breadcrumb>
        </Container>
        <Container>
          <br />
          <h5>Quy Chế Huấn Luyện</h5>
          <p>
            Quy Chế Huấn Luyện là những quy định và hướng dẫn căn bản, nhằm mục
            đích thống nhất và kiện toàn các phương cách huấn luyện và đào tạo
            các cấp Lãnh Ðạo Phục Vụ trong Phong Trào Thiếu Nhi Thánh Thể Việt
            Nam từ hình thức cho đến nội dung.
          </p>
          <p>
            Như xưa Thiên Chúa dẫn đưa dân Người vào sa mạc để được thanh luyện
            40 năm trong hoang địa trước khi vào Ðất Hứa, ngày nay Phong Trào
            Thiếu Nhi Thánh Thể cũng dùng khung cảnh thiên nhiên làm môi trường
            căn bản để huấn luyện. Phương pháp vào sa mạc nay bao gồm tất cả
            những chiều kích siêu nhiên và tự nhiên giúp cho việc huấn luyện
            Thiếu Nhi Thánh Thể đạt được nhiều kết quả.
          </p>
          <br />
        </Container>
        <Container>
          <ResourceTable resources={mappedResources} />
          {/* <tr>
                <td valign="top ">
                  <strong>NHỮNG QUY ĐỊNH CHUNG VỀ HÀNH CHÁNH SA MẠC 2014</strong>
                </td>
                <td valign="top "
                >
                  <a href="/resources/HuanLuyen/QCHL2011.pdf "  target="_blank" rel="noopener noreferrer">PDF</a>
                </td>
              </tr> */}
          {/* File not found */}
        </Container>
      </Layout>
    );
  }
}

export default TrainingRegulations;

export const pageQuery = graphql`
  query TrainingRegulationsQuery {
    allTrainingRegulationsListJson {
      edges {
        node {
          name
          type
          links {
            fileType
            fileLink
          }
          categoryStyle {
            background
            border
          }
          categoryTextStyle {
            color
          }
          updated
        }
      }
    }
  }
`;

export const frontmatter = {
  title: 'Training Regulations',
  category: 'resources',
  path: '/resources/trainingregulations',
  description: 'Training Regulations',
  tags: 'resource, training regulations, quy che huan luyen, ',
};
